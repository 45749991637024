import * as routes from 'lib/routes';

import { apiSlice } from 'api/base_slice';
import { TEAM_PROJECTS_TAG } from 'api/tag_types';
import { deserializeJSONApiResponse, sortAlphabeticalByName } from 'api/utils';

// TODO: Find graceful way to switch between sorted or not, likely backend?
// Also researched passing args into transformResponse, but would not work even with upgrade
// eslint-disable-next-line max-len
// https://stackoverflow.com/questions/73070967/how-to-access-the-query-arguments-in-transformresponse-with-redux-toolkit-query
// transformResponse: (response, meta, arg) => {

export const teamProjectsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTeamProjects: builder.query({
      query: ({ teamId, ...rest }) =>
        routes.api_team_projects_path({ team_id: teamId, ...rest }),
      transformResponse: (response) =>
        sortAlphabeticalByName(deserializeJSONApiResponse(response)),
      providesTags: [TEAM_PROJECTS_TAG],
    }),
    getUnsortedTeamProjects: builder.query({
      query: ({ teamId, ...rest }) =>
        routes.api_team_projects_path({ team_id: teamId, ...rest }),
      transformResponse: deserializeJSONApiResponse,
      providesTags: (_result, _error, { id }) => [
        { type: TEAM_PROJECTS_TAG, id },
      ],
    }),
  }),
  overrideExisting: 'throw',
});

export const { useGetTeamProjectsQuery, useGetUnsortedTeamProjectsQuery } =
  teamProjectsApi;
