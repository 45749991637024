import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { projectsApi } from 'api/projects';

import { buildProjectState } from 'researcher/project_workspace/hooks/use_get_project_state';

import {
  ACTIVITY_STEP,
  DETAILS_STEP,
  EXPERIENCE_STEP,
  RECRUITMENT_STEP,
} from './constants';
import {
  buildActivityStepFields,
  buildDetailsStepFields,
  buildExperienceStepFields,
  buildRecruitmentStepFields,
} from './store/utils';

export const STEPS_REQUIRED_FIELDS_SCHEMA = {
  [ACTIVITY_STEP]: buildActivityStepFields,
  [DETAILS_STEP]: buildDetailsStepFields,
  [EXPERIENCE_STEP]: buildExperienceStepFields,
  [RECRUITMENT_STEP]: buildRecruitmentStepFields,
};

const matchProjectFetched = isAnyOf(
  projectsApi.endpoints.getProject.matchFulfilled,
);

const calculateStepCompletionPercentage = ({
  invalidCount,
  totalRequiredCount,
}) => {
  if (invalidCount === 0 && totalRequiredCount === 0) {
    return 100;
  }

  return Math.round(
    ((totalRequiredCount - invalidCount) / totalRequiredCount) * 100,
  );
};

const initializeForm = (stepId) => ({
  stepId,
});

const initializePrimaryForm = (stepId) => ({
  ...initializeForm(stepId),
  syncedValues: {},
  isValid: false,
});

const initializeStep = (stepId) => ({
  id: stepId,
  forms: {
    primary: initializePrimaryForm(stepId),
  },
  metrics: {
    invalidCount: 0,
    totalRequiredCount: 0,
  },
});

const initialState = {
  researchDesign: {
    currentStepId: null,
    steps: {
      [ACTIVITY_STEP]: initializeStep(ACTIVITY_STEP),
      [DETAILS_STEP]: initializeStep(DETAILS_STEP),
      [EXPERIENCE_STEP]: initializeStep(EXPERIENCE_STEP),
      [RECRUITMENT_STEP]: initializeStep(RECRUITMENT_STEP),
    },
  },
};

export const projectWorkspaceSlice = createSlice({
  name: 'projectWorkspace',
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    formPrimaryRegister(state, { payload }) {
      const { stepId, defaultValues, isValid } = payload;

      state.researchDesign.currentStepId = stepId;
      state.researchDesign.steps[stepId].forms.primary.syncedValues =
        defaultValues;
      state.researchDesign.steps[stepId].forms.primary.isValid = isValid;
    },
    formPrimarySyncState(state, { payload }) {
      const { stepId, values, isValid } = payload;

      state.researchDesign.steps[stepId].forms.primary.syncedValues = {
        ...state.researchDesign.steps[stepId].forms.primary.syncedValues,
        ...values,
      };
      state.researchDesign.steps[stepId].forms.primary.isValid = isValid;
    },
  },
  extraReducers(builder) {
    /* the following logic handles updating step progressions after
       a user hits the save button. This general procedure is followed:

       1. watch for any RTK-Q project query to resolve
       2. grab the invalidLaunchProps from the meta key on the json:api payload
       3. loop over each step form (primary and drawers) to build our metrics state by
          looking for the intersection between invalid launch props (from the server)
          and required fields for that particular form.
    */
    builder.addMatcher(matchProjectFetched, (state, { payload }) => {
      const { invalidLaunchProps, isDraft } = buildProjectState(payload);
      const { steps } = state.researchDesign;

      if (!isDraft || !invalidLaunchProps) return;

      Object.entries(steps).forEach(([stepId, step]) => {
        const requiredFieldsFn = STEPS_REQUIRED_FIELDS_SCHEMA[stepId];
        const requiredFields = requiredFieldsFn(payload);

        if (requiredFields) {
          step.metrics.totalRequiredCount = requiredFields.length;
          step.metrics.invalidCount = requiredFields.filter((field) =>
            invalidLaunchProps.includes(field),
          ).length;
        }
      });
    });
  },
  /* eslint-enable no-param-reassign */
});

export const { formPrimaryRegister, formPrimarySyncState } =
  projectWorkspaceSlice.actions;

const rootSelector = (state) => state.projectWorkspace;

const stepsSelector = createSelector(
  rootSelector,
  (state) => state.researchDesign.steps,
);

export const currentStepSelector = createSelector(rootSelector, (state) => {
  const { currentStepId, steps } = state.researchDesign;
  return steps[currentStepId];
});

export const currentStepFormSelector = createSelector(
  currentStepSelector,
  (currentStep) => currentStep?.forms.primary,
);

export const stepMetricsSelector = createSelector(
  stepsSelector,
  (_, stepId) => stepId,
  (steps, stepId) => {
    const { invalidCount, totalRequiredCount } = steps[stepId].metrics;

    return {
      completionPercentage: calculateStepCompletionPercentage({
        invalidCount,
        totalRequiredCount,
      }),
    };
  },
);
