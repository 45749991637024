import { registerComponent } from 'lib/react_on_rails';

import { SidebarEntrypoint } from 'components/page/components/sidebar/sidebar_entrypoint';
import { FooterEntrypoint } from 'components/page/components/footer/footer_entrypoint';

// eslint-disable-next-line local-rules/css_modules
import 'common/error_pages/error_pages.scss';

registerComponent('Sidebar', SidebarEntrypoint);
registerComponent('Footer', FooterEntrypoint);
