import {
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
  type RefObject,
} from 'react';
import { isServer } from 'lib/ssr_utils';
import { useSidebarMobileBreakpoint } from 'hooks/use_sidebar_mobile_breakpoint';

const SidebarOpenDebounceMS = 200;
const SidebarCloseDebounceMS = 125;

type UseIsSidebarOpenArgs = {
  isNavItemHovered: boolean;
  isSidebarHovered: boolean;
  sidebarRef: RefObject<HTMLElement | null>;
};

export function useIsSidebarOpen({
  sidebarRef,
  isNavItemHovered,
  isSidebarHovered,
}: UseIsSidebarOpenArgs) {
  const [isOpen, setIsOpen] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const openSidebarTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const closeSidebarTimeout = useRef<ReturnType<typeof setTimeout> | null>(
    null,
  );

  const isMobile = useSidebarMobileBreakpoint();
  const isReducedMotion = useMemo(() => {
    if (isServer) return true;

    return window.matchMedia('(prefers-reduced-motion: reduce)').matches;
  }, []);

  const openSidebar = useCallback(() => {
    if (isOpen || isMobile) return;

    if (openSidebarTimeout.current) {
      clearTimeout(openSidebarTimeout.current);
    }

    if (closeSidebarTimeout.current) {
      clearTimeout(closeSidebarTimeout.current);
    }

    openSidebarTimeout.current = setTimeout(() => {
      if (!isReducedMotion) setIsTransitioning(true);

      setIsOpen(true);
    }, SidebarOpenDebounceMS);
  }, [isMobile, isOpen, isReducedMotion]);

  const closeSidebar = useCallback(() => {
    if (!isOpen) return;

    if (openSidebarTimeout.current) {
      clearTimeout(openSidebarTimeout.current);
    }

    if (closeSidebarTimeout.current) {
      clearTimeout(closeSidebarTimeout.current);
    }

    closeSidebarTimeout.current = setTimeout(() => {
      if (!isReducedMotion) setIsTransitioning(true);

      setIsOpen(false);
    }, SidebarCloseDebounceMS);
  }, [isOpen, isReducedMotion]);

  // This effect sets transitioning state back to false once the css transition has completed (via open or close)
  useEffect(() => {
    if (!sidebarRef.current) return undefined;

    const { current: sidebar } = sidebarRef;

    function handleTransitionEnd(event: TransitionEvent) {
      if (event.propertyName !== 'width') return;

      setIsTransitioning(false);
    }

    sidebar.addEventListener('transitionend', handleTransitionEnd);

    return () => {
      sidebar.removeEventListener('transitionend', handleTransitionEnd);
    };
  }, [sidebarRef]);

  useEffect(() => {
    if (isTransitioning) return;

    if (isNavItemHovered) openSidebar();
    if (!isNavItemHovered && !isSidebarHovered) closeSidebar();
  }, [
    closeSidebar,
    isNavItemHovered,
    isSidebarHovered,
    isTransitioning,
    openSidebar,
  ]);

  useEffect(() => {
    setIsOpen(false);
  }, [isMobile]);

  return { isOpen, isTransitioning };
}
