/* eslint-disable camelcase */
import type {
  RequestOperations,
  PathParameters,
  ResponseBody,
} from 'lib/swagger_helper';
import * as routes from 'lib/routes';
import { apiSlice } from 'api/base_slice';
import { POPULATION_LABELS_TAG } from 'api/tag_types';
import { headersAndCredentials } from 'api/utils';

export type ParticipantPopulationLabelsRawResponse =
  ResponseBody<'getParticipantPopulationLabels'>;
export type BulkParticipantPopulationLabelOperations =
  RequestOperations<'bulkParticipantPopulationLabels'>;

function transformResponse(response: ParticipantPopulationLabelsRawResponse) {
  const { data } = response;

  return data
    .sort((a, b) => a.attributes.label.localeCompare(b.attributes.label))
    .map((raw) => ({
      ...raw.attributes,
      id: raw.id,
      meta: raw.meta,
      // older code expects this transformation of API response state
      value: raw.attributes.label,
    }));
}

export type ParticipantPopulationLabelsApiResponse = ReturnType<
  typeof transformResponse
>;

export const populationLabelsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPopulationLabels: builder.query<
      ParticipantPopulationLabelsApiResponse,
      PathParameters<'getParticipantPopulationLabels'>
    >({
      query: ({ participant_population_id }) =>
        routes.api_participant_population_labels_path(
          participant_population_id,
        ),
      providesTags: [POPULATION_LABELS_TAG],
      transformResponse,
    }),
    bulkPopulationLabels: builder.mutation<
      unknown,
      {
        operations: BulkParticipantPopulationLabelOperations;
      } & PathParameters<'bulkParticipantPopulationLabels'>
    >({
      query: ({ operations, participant_population_id }) => ({
        url: routes.bulk_api_participant_population_labels_path(
          participant_population_id,
        ),
        method: 'POST',
        body: { 'atomic:operations': operations },
        ...headersAndCredentials(),
      }),
      invalidatesTags: [POPULATION_LABELS_TAG],
    }),
  }),
  overrideExisting: 'throw',
});

export const { useGetPopulationLabelsQuery, useBulkPopulationLabelsMutation } =
  populationLabelsApi;
