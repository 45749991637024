// eslint-disable-next-line no-restricted-imports
import { getCSRFToken } from 'lib/csrf';

import Jsona from 'jsona';

export const dataFormatter = new Jsona();

const ACCEPT_V1_HEADER = 'application/json';
export const ACCEPT_V2_HEADER = 'application/vnd.user-interviews.v2';

export const headersAndCredentials = (accept = ACCEPT_V1_HEADER) => ({
  headers: {
    Accept: accept,
    'X-Requested-With': 'XMLHTTPRequest',
    'X-CSRF-Token': getCSRFToken() as string | undefined,
  },
  credentials: 'same-origin' as RequestCredentials,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deserializeJSONApiResponse = (response: any) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataFormatter.deserialize(response) as any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deserializeJSONApiResponseWithMeta = (response: any) => {
  const data = dataFormatter.deserialize(response);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return { data: data as any, meta: response.meta as any };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseJSONApiResponse = (schema: Zod.Schema) => (response: any) =>
  schema.parse(deserializeJSONApiResponse(response));

export const parseJSONApiResponseWithMeta =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (schema: Zod.Schema) => (response: any) =>
    schema.parse(deserializeJSONApiResponseWithMeta(response));

const sortAlphabeticalByKey = <T>(objectArray: T[], key: keyof T) =>
  objectArray.sort((a, b) =>
    (a[key] as string).localeCompare(b[key] as string),
  );

export const sortAlphabeticalByName = <T>(
  objectArray: Array<T & { name: string }>,
) => sortAlphabeticalByKey(objectArray, 'name');

export const sortAlphabeticalByTitle = <T>(
  objectArray: Array<T & { title: string }>,
) => sortAlphabeticalByKey(objectArray, 'title');
