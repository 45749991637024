import React, { type ComponentType } from 'react';
import { Provider } from 'react-redux';
import { ErrorBoundary as AppsignalErrorBoundary } from '@appsignal/react';

import store from 'store/store';
import { DopeBrowserRouter } from 'components/dope_browser_router';
import { useTrackingWithDefaultDispatch } from 'hooks/use_tracking_with_default_dispatch';
import { RailsContextProvider, useRailsContext } from 'hooks/use_rails_context';
// eslint-disable-next-line no-restricted-imports
import appsignal from 'lib/telemetry/appsignal';

import { getDisplayName } from './utils';

type PageProvidersProps = {
  children: React.ReactNode;
};

function PageProviders({ children }: PageProvidersProps) {
  const { Track } = useTrackingWithDefaultDispatch();
  const railsContext = useRailsContext();

  if (!appsignal) {
    return (
      <DopeBrowserRouter location={railsContext?.request.location ?? '/'}>
        <Provider store={store}>
          <Track>{children}</Track>
        </Provider>
      </DopeBrowserRouter>
    );
  }

  return (
    <AppsignalErrorBoundary
      fallback={
        railsContext?.env.APPSIGNAL_FALLBACK
          ? (error?: Error) => (
              <div className="ui-error-boundary-fallback">
                There was an error loading this component
                <span aria-label="warning" role="img">
                  {' '}
                  ⚠️{' '}
                </span>
                {error && error.message}
              </div>
            )
          : undefined
      }
      instance={appsignal}
    >
      <DopeBrowserRouter location={railsContext?.request.location ?? '/'}>
        <Provider store={store}>
          <Track>{children}</Track>
        </Provider>
      </DopeBrowserRouter>
    </AppsignalErrorBoundary>
  );
}

export function withPageProviders<T extends object>(
  WrappedComponent: ComponentType<T>,
) {
  function WithPageProviders(props: T, railsContext: unknown) {
    return function () {
      return (
        <RailsContextProvider railsContext={railsContext}>
          <PageProviders>
            <WrappedComponent {...props} />
          </PageProviders>
        </RailsContextProvider>
      );
    };
  }

  WithPageProviders.displayName = `withPageProviders(${getDisplayName(WrappedComponent)})`;
  return WithPageProviders;
}
