import * as routes from 'lib/routes';

import { apiSlice } from 'api/base_slice';

import { POPULATION_INVITE_FILTERS_TAG } from 'api/tag_types';
import { deserializeJSONApiResponse, headersAndCredentials } from 'api/utils';

export const populationInviteFiltersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createInviteFilter: builder.mutation({
      query: ({ populationId, attributes }) => ({
        url: routes.api_participant_population_invite_filters_path({
          participant_population_id: populationId,
        }),
        method: 'POST',
        body: {
          data: { attributes },
        },
        ...headersAndCredentials(),
      }),
      invalidatesTags: [POPULATION_INVITE_FILTERS_TAG],
    }),
    getInviteFilter: builder.query({
      query: ({ populationId, id }) =>
        routes.api_participant_population_invite_filter_path(id, {
          participant_population_id: populationId,
        }),
      providesTags: (_result, _error, { id }) => [
        { type: POPULATION_INVITE_FILTERS_TAG, id },
      ],
      transformResponse: deserializeJSONApiResponse,
    }),
    updateInviteFilter: builder.mutation({
      query: ({ populationId, id, attributes }) => ({
        url: routes.api_participant_population_invite_filter_path(id, {
          participant_population_id: populationId,
        }),
        method: 'PATCH',
        body: {
          data: { attributes },
        },
        ...headersAndCredentials(),
      }),
      invalidatesTags: [POPULATION_INVITE_FILTERS_TAG],
    }),
  }),
  overrideExisting: 'throw',
});

export const {
  useGetInviteFilterQuery,
  useCreateInviteFilterMutation,
  useUpdateInviteFilterMutation,
} = populationInviteFiltersApi;
