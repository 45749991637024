import { apiSlice } from 'api/base_slice';
import { columnConfigurationSliceReducer } from 'store/slices/column_configuration_slice';
import { segmentsSliceReducer } from 'store/slices/segments_slice';
import { participantProfileSliceReducer } from 'store/slices/participant_profile_slice';
import { projectDetailsSliceReducer } from 'store/slices/project_details_slice';

import { projectWorkspaceSlice } from 'researcher/project_workspace/slice';

export const rootReducer = {
  [apiSlice.reducerPath]: apiSlice.reducer,
  columnConfiguration: columnConfigurationSliceReducer,
  participantProfile: participantProfileSliceReducer,
  projectDetails: projectDetailsSliceReducer,
  projectWorkspace: projectWorkspaceSlice.reducer,
  segments: segmentsSliceReducer,
};
