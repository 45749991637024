import React, { useState } from 'react';
import { JsonApiDataStore } from 'jsonapi-datastore';
import { LoadingOverlay } from '@user-interviews/ui-design-system';

import { AuthUserContext } from 'common/authorization';
import useHttp from 'hooks/use_http';

import { ErrorRenderer } from 'lib/errors';
import * as routes from 'lib/routes';

import { getDisplayName } from './utils';

// authUserParams() takes props and returns a object of params to merge into the API call to
// /authorization-user.
// ex. props => ({ project_id: 17 })
//
// defaultValue() takes props and returns a object to use as the default value for the context
// The object should be in the format expected by JsonAPIDataStore
// ex. props => props.authUser
function withAuthUserContext(
  WrappedComponent,
  authUserParams = () => ({}),
  defaultValue = (props) => props.authUser || {},
) {
  function WithAuthUserContext(props) {
    const { get, loading } = useHttp();

    const [contextValue, setContextValue] = useState(
      new JsonApiDataStore().sync(defaultValue(props)),
    );
    const [loadingOverlayEnabled, setLoadingOverlayEnabled] = useState(true);

    const refreshAuthUserContext = async ({
      skipLoadingOverlay = false,
    } = {}) => {
      if (skipLoadingOverlay) {
        setLoadingOverlayEnabled(false);
      }

      const contextData = await get(
        routes.api_authorization_user_path(authUserParams(props)),
        { onError: ErrorRenderer.alert },
      ).finally(() => setLoadingOverlayEnabled(true));

      if (contextData) {
        setContextValue(contextData);
      }
    };

    return (
      // TODO: RS-19294
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      <AuthUserContext.Provider
        value={{ ...contextValue, refreshAuthUserContext }}
      >
        <LoadingOverlay visible={loadingOverlayEnabled && loading} />
        <WrappedComponent {...props} />
      </AuthUserContext.Provider>
    );
  }

  WithAuthUserContext.displayName = `withAuthUserContext(${getDisplayName(WrappedComponent)})`;

  return WithAuthUserContext;
}

export default withAuthUserContext;
