/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isActive: true,
};

export const projectDetailsSlice = createSlice({
  name: 'projectDetails',
  initialState,
  reducers: {
    ADD_WORKSPACE_ATTRIBUTES: (state, action) => {
      const { attributes } = action.payload;

      state.isActive = !!attributes?.isActive;
    },
  },
});

export const { ADD_WORKSPACE_ATTRIBUTES } = projectDetailsSlice.actions;

export const projectDetailsSliceReducer = projectDetailsSlice.reducer;
