import { useLocation } from 'react-router';
import * as routes from 'lib/routes';

// We have two primary navigation elements: Projects and Participants.
// However, the pages the fall under these two categories span a number of different URL schemes.
// For now, rather than changing our existing URL schemes to match the information architecture,
// this hook instead just maps these URLs to their corresponding primary nav element.
// eg:
//
// Project nav items:
// - pre-builder  /start
// - builder      /projects/builder/:id
// - workspace    /projects/:id
//
// Participant nav items:
// - RPT /teams/:id/recruit-participants
// - Hub /research-hub/participants
// - Opt in forms /participant-populations/:id

export function useIsActive(path: string) {
  const { pathname } = useLocation();

  if (pathname === path) return true;

  if (path === routes.account_dashboard_path()) {
    const projectRoutes = [
      /\/start/,
      /\/projects\/builder\/\d+/,
      /\/projects\/\d+/,
      /\/projects\/review/,
    ];
    return projectRoutes.some((regExp) => pathname.match(regExp));
  }

  if (path === routes.search_participants_path()) {
    const participantRoutes = [
      /\/teams\/\d+\/recruit-participants/,
      /\/research-hub\/participants/,
      /\/participant-populations\/\d+/,
    ];

    return participantRoutes.some((regExp) => pathname.match(regExp));
  }

  return false;
}
